import React from 'react';

function GenerativeArt() {
    return (
        <div>
            <h1>GenerativeArt</h1>
        </div>
    );
}

export default GenerativeArt;