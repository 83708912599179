import React, { useEffect } from 'react';
import './FourthProject.css';

function FirstProject() {

    return (
        <div>
            <div className="parallax">
                <h1>SURFANA</h1>
                <h5>This project is still in process</h5>
            </div>
            <div style={{ height: '2000px' }}> {/* Just for scrolling space */}
            </div>
        </div>
    );
}

export default FirstProject;